import {HexStr} from "../../../store/web3/web3";
import {
  NetworkType,
  AddressType,
  PrivateKeyType,
  IAccount,
  IMapValueByAddress,
  IGeneralTxData, ITransactionPriorityEnum
} from "../../ConsolidationTool/types";
import {
  IDataEstimateDisperseTransactions,
  IEstimateFeeForDisperseResultType,
  IDataEstimateUnitsForMultiTransaction,
  IDataSendDisperseTransactions, IDataEstimateUnitsForSingleTransaction
} from "../types";
import {IWeb3DisperseFacade} from "./IWeb3DisperseFacade";
import {Keypair, PublicKey, TransactionInstruction, VersionedTransaction} from "@solana/web3.js";
import {InitERCDisperseDataType} from "./ETH_DisperseFacade";
import {
  devNetProvidersRpcHttp,
  LimitRpcHttp,
  mainNetProvidersRpcHttp, MICRO_LAMPORTS_PER_LAMPORT,
  setProviderWeb3, Web3SolType
} from "../../../store/web3/web3Sol";
import {GasHelper, getPrecisionByNumber} from "../../../helpers";
import {Web3Account} from "web3-eth-accounts";
import {JsonRpcError} from "web3-types/src/json_rpc_types";
import {IRpcResponse} from "../../../models/chainScan.models";
import {partitionMapIntoChunks} from "../../../helpers/toChunks";

export type InitSOLDisperseDataType = {
  network: NetworkType,
  linkForTxScan: string,
  defaultTransactionPriority: keyof ITransactionPriorityEnum,

  limitAddresses?: number,
  addressesChunkSize?: number,
}

const SOLInitDisperseData: InitSOLDisperseDataType = {
  network: "sol",
  linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_SOL_SCAN,
  defaultTransactionPriority: "veryHigh",
}

class SOL_DisperseFacade implements IWeb3DisperseFacade {
  protected readonly _feeDefaultInLamports = 5_000;
  protected readonly _txInstructionsPerTransaction = 5;

  protected readonly _network: NetworkType;
  protected _linkForTxScan: string;
  protected _defaultTransactionPriority: string;

  protected readonly limitAddresses: number;
  protected readonly addressesChunkSize: number;

  protected readonly _environment;

  private readonly __web3ProviderIterator;
  private readonly __web3ProviderByLink = new Map();
  private __web3ProviderPriority: LimitRpcHttp | null;

  constructor(initData?: InitERCDisperseDataType) {
    this._network = initData?.network || SOLInitDisperseData.network
    this._linkForTxScan = initData?.linkForTxScan || SOLInitDisperseData.linkForTxScan
    this._defaultTransactionPriority = initData?.defaultTransactionPriority || SOLInitDisperseData.defaultTransactionPriority

    this.limitAddresses = initData?.limitAddresses || 990
    this.addressesChunkSize = initData?.addressesChunkSize || 990

    this._environment = process.env.REACT_APP_ENVIRONMENT

    this.__web3ProviderIterator = this._getWeb3ProviderIterator()
  }

  get linkForTxScan() {
    return this._linkForTxScan
  }

  get network() {
    return this._network
  }

  getLimitReceiver(): number {
    return this.limitAddresses
  }

  getChunkSize(): number {
    return this.addressesChunkSize
  }

  protected set _web3ProviderLimit(limit: LimitRpcHttp | null) {
    this.__web3ProviderPriority = limit
  }

  getTimeout(): number {
    return 200;
  }

  protected get _web3Provider(): Web3SolType {
    return this.__web3ProviderIterator.next().value
  }

  * _getWeb3ProviderIterator() {
    const providersRpcHttp = process.env.REACT_APP_ENVIRONMENT === 'dev' ? devNetProvidersRpcHttp : mainNetProvidersRpcHttp

    while (true) {
      for (const {rpcHttp, limit} of providersRpcHttp) {

        if (this.__web3ProviderPriority && limit < this.__web3ProviderPriority) continue

        if (!this.__web3ProviderByLink.has(rpcHttp)) {
          this.__web3ProviderByLink.set(rpcHttp, setProviderWeb3(rpcHttp))
        }

        this._web3ProviderLimit = null

        yield this.__web3ProviderByLink.get(rpcHttp)
      }
    }
  }

  async estimateTransactions(data: IDataEstimateDisperseTransactions): Promise<IEstimateFeeForDisperseResultType> {
    //calculate fee without optimization
    const notOptimizedFeeInUnit: bigint = await this._estimateUnitsForSingleTransactions(data)

    // calculate fee with optimization
    const optimizedFeeInUnit: bigint = await this._estimateUnitsForMultiTransaction(data)

    const rentExemptionInLamports = await this._getRentExemptionInLamports(data.senderAccount.address)

    return {
      notOptimizedFeeInUnit: notOptimizedFeeInUnit + rentExemptionInLamports,
      optimizedFeeInUnit: optimizedFeeInUnit + rentExemptionInLamports
    }
  }

  async _estimateUnitsForSingleTransactions(data: IDataEstimateUnitsForSingleTransaction): Promise<bigint> {
    const {totalFee} = await this._estimatePriorityFeeForSingleTransaction(data)

    return totalFee * BigInt(data.amountInUnitByReceiver.size)
  }

  async _estimatePriorityFeeForSingleTransaction(data: IDataEstimateUnitsForMultiTransaction): Promise<{
    totalFee: bigint,
    computeLimitIx: TransactionInstruction,
    computePriceIx?: TransactionInstruction
  }> {
    const {PublicKey, SystemProgram, getKeyPair} = this._web3Provider

    const receiver = data.amountInUnitByReceiver.keys().next().value
    const signer = getKeyPair(data.senderAccount.privateKey)

    return await this._getPriorityFeeEstimate(
      [SystemProgram.transfer({
        fromPubkey: signer.publicKey,
        toPubkey: new PublicKey(receiver),
        lamports: 0,
      })],
      signer,
    )
  }

  async _estimateUnitsForMultiTransaction(data: IDataEstimateUnitsForMultiTransaction): Promise<bigint> {
    const numTransactions = Math.ceil(data.amountInUnitByReceiver.size / this._txInstructionsPerTransaction);

    const {totalFee} = await this._estimatePriorityFeeForMultiTransaction(data)

    return totalFee * BigInt(numTransactions)
  }

  async _estimatePriorityFeeForMultiTransaction(data: IDataEstimateUnitsForMultiTransaction): Promise<{
    totalFee: bigint,
    computeLimitIx: TransactionInstruction,
    computePriceIx?: TransactionInstruction
  }> {
    const {PublicKey, SystemProgram, getKeyPair} = this._web3Provider
    const signer = getKeyPair(data.senderAccount.privateKey)

    let txInstructions: TransactionInstruction[] = []
    const addressesIterator = data.amountInUnitByReceiver.keys();
    const upperIndex = Math.min(data.amountInUnitByReceiver.size, this._txInstructionsPerTransaction)
    for (let i = 0; i < upperIndex; i++) {
      let receiver = addressesIterator.next().value;
      txInstructions.push(
        SystemProgram.transfer({
          fromPubkey: signer.publicKey,
          toPubkey: new PublicKey(receiver),
          lamports: 0,
        })
      );
    }

    return await this._getPriorityFeeEstimate(txInstructions, signer)
  }

  async _getRentExemptionInLamports(sender: AddressType): Promise<bigint> {
    const {getAccountInfo, PublicKey, getMinimumBalanceForRentExemption} = this._web3Provider

    const accountInfo = await getAccountInfo(new PublicKey(sender));
    const data = accountInfo?.data as Uint8Array | undefined
    //minimum lamports required in the Account to remain rent free
    const rentExemptionInLamports = await getMinimumBalanceForRentExemption(data?.length || 0);

    return BigInt(rentExemptionInLamports)
  }

  async _getPriorityFeeEstimate(txInstructions: TransactionInstruction[], sender: Keypair): Promise<{
    totalFee: bigint,
    computeLimitIx: TransactionInstruction,
    computePriceIx?: TransactionInstruction
  }> {
    type SimResp = {
      "accounts": any | null,
      "err": any | null,
      "innerInstructions": any | null,
      "logs": string[],
      "returnData": any | null,
      "unitsConsumed": number
    }
    /**
     * Lamports per signature: 5000
     * Base Transaction Fee already set, which is 5000 lamports per signature in your transaction
     * @inheritDoc https://solana.com/developers/guides/advanced/how-to-use-priority-fees#what-are-priority-fees
     */
    const FeeDefault = this._feeDefaultInLamports

    /**
     * A transfer SOL transaction takes 300 CU
     * @inheritDoc https://solana.com/developers/guides/advanced/how-to-use-priority-fees#how-do-i-implement-priority-fees
     */
    const ComputeUnitsDefault = 200_000 as const
    const PriorityFeeDefault = BigInt(0) as const //for priorityFeeLevel [min]

    const {
      getLatestBlockhash,
      getPriorityFeeEstimate,
      ComputeBudgetProgram,
      simulateTransaction,
      connection,
      BatchTransaction
    } = this._web3Provider


    let computeLimitIx = ComputeBudgetProgram.setComputeUnitLimit({
      units: ComputeUnitsDefault
    });


    let priorityFee: bigint = PriorityFeeDefault
    let unitLimit: number = ComputeUnitsDefault
    const recentHash = await getLatestBlockhash("finalized")
    const _tx = new BatchTransaction({
      recentBlockhash: recentHash.blockhash
    })
    _tx.add(computeLimitIx)
    txInstructions.forEach(instruction => _tx.add(instruction))
    _tx.sign(sender)
    const {
      error,
      result
    } = await getPriorityFeeEstimate(_tx.serialize(), sender.publicKey.toString())
    if (!error && result?.priorityFeeLevels) {
      //Receive `priorityFee` in microLamports
      priorityFee = GasHelper.gasPay(Number(result.priorityFeeLevels[this._defaultTransactionPriority] || 0))
      console.log("=> priorityFee", priorityFee);
    }

    const computePriceIx = ComputeBudgetProgram.setComputeUnitPrice({
      microLamports: priorityFee
    });

    /**
     * Simulate transaction for receive ComputeUnit
     */
    const {blockhash} = await getLatestBlockhash("finalized")
    const _txForSimulate = new BatchTransaction({
      recentBlockhash: blockhash
    })
    if (priorityFee) {
      _txForSimulate.add(computePriceIx)
    }
    _txForSimulate.add(computeLimitIx)
    txInstructions.forEach(instruction => _txForSimulate.add(instruction))
    _txForSimulate.sign(sender)

    //this approach is better than use ComputeUnitsDefault, but does not work stable
    const testVersionedTxn = new VersionedTransaction(_txForSimulate.compileMessage());
    const simulation = await simulateTransaction(testVersionedTxn);
    if (!simulation.value.err) {
      const responseData = simulation.value as SimResp
      unitLimit = Number(GasHelper.gasPay(responseData.unitsConsumed)) // add 20% of amount just in case
      computeLimitIx = ComputeBudgetProgram.setComputeUnitLimit({
        units: unitLimit
      });
    }

    /**
     * Estimate fee
     */
    const {blockhash: recentBlockhash} = await getLatestBlockhash("finalized")
    const _txForEstimate = new BatchTransaction({
      recentBlockhash: recentBlockhash
    })
    if (priorityFee) {
      _txForEstimate.add(computePriceIx)
    }
    _txForEstimate.add(computeLimitIx)
    txInstructions.forEach(instruction => _txForEstimate.add(instruction))
    _txForEstimate.sign(sender)
    const dataEstimate = await _txForEstimate.getEstimatedFee(connection)

    // Calculate total fee (base fee + (compute unit * priority fee))
    const baseFee = BigInt(dataEstimate || FeeDefault * _txForEstimate.signatures.length)
    //Convert priorityFee in microLamports to Lamports if it`s more 0
    const computeUnitFeeInLamports = priorityFee ? Math.ceil(Number(priorityFee) / MICRO_LAMPORTS_PER_LAMPORT) : 1
    const totalFeeInLamports = baseFee + BigInt(computeUnitFeeInLamports * unitLimit);

    return {
      totalFee: totalFeeInLamports,
      computePriceIx: priorityFee ? computePriceIx : undefined,
      computeLimitIx
    }
  }

  sendTransactions(data: IDataSendDisperseTransactions): Promise<IMapValueByAddress> {
    const {isOptimizedFee} = data

    if (isOptimizedFee) {
      return this._sendMultiTransaction({
        amountInUnitByReceiver: data.amountInUnitByReceiver,
        senderAccount: data.senderAccount as Web3Account,
        totalSendInUnit: data.totalSendInUnit,
        senderBalance: data.senderBalance,
      })
    } else {
      return this._sendSingleTransactions({
        amountInUnitByReceiver: data.amountInUnitByReceiver,
        senderAccount: data.senderAccount as Web3Account,
        totalSendInUnit: data.totalSendInUnit,
        senderBalance: data.senderBalance,
      })
    }
  }

  async _sendSingleTransactions(data: {
    amountInUnitByReceiver: IMapValueByAddress<bigint>;
    senderAccount: IAccount;
    senderBalance: bigint;
    totalSendInUnit: bigint;
  }): Promise<IMapValueByAddress<string>> {
    const {amountInUnitByReceiver, senderAccount, senderBalance, totalSendInUnit} = data;
    const resultTxReceipt: IMapValueByAddress<string> = new Map()

    const {
      getLatestBlockhash, BatchTransaction, BatchRequest,
      sendRawTransaction, SystemProgram, getKeyPair, PublicKey
    } = this._web3Provider

    const signedTxByAddress = new BatchRequest()

    const receiver = amountInUnitByReceiver.keys().next().value
    if (!receiver) {
      return resultTxReceipt
    }
    const signer = getKeyPair(senderAccount.privateKey)

    const {
      totalFee,
      computeLimitIx,
      computePriceIx
    } = await this._estimatePriorityFeeForSingleTransaction(data)

    const rentExemptionInLamports = await this._getRentExemptionInLamports(senderAccount.address)
    const totalAmount = totalFee * BigInt(amountInUnitByReceiver.size) + totalSendInUnit
    if (senderBalance - totalAmount <= rentExemptionInLamports) {
      console.error("Not enough balance to send transactions")
      return resultTxReceipt
    }

    for (const address of amountInUnitByReceiver.keys()) {
      const {blockhash} = await getLatestBlockhash("finalized")
      let transaction = new BatchTransaction({
        recentBlockhash: blockhash,
      })

      if (computePriceIx) {
        transaction.add(
          computePriceIx,
        )
      }

      transaction.add(
        computeLimitIx,
        SystemProgram.transfer({
          fromPubkey: signer.publicKey,
          toPubkey: new PublicKey(address),
          lamports: amountInUnitByReceiver.get(address)!,
        })
      )

      transaction.sign(signer)

      signedTxByAddress.add(sendRawTransaction.request(transaction.serialize(), address))
    }

    const dataBatchTx = await signedTxByAddress.execute({timeout: 30000})
    for (let txResult of dataBatchTx) {
      if (txResult.error) {
        const errorData = txResult.error as JsonRpcError
        throw new Error(`${errorData.message} [${errorData.code}]`)
      }
      let itemSuccess = txResult as IRpcResponse
      if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
        itemSuccess.result += '?cluster=devnet'
      }
      resultTxReceipt.set(itemSuccess.id, itemSuccess.result as HexStr)
    }
    return resultTxReceipt
  }

  async _sendMultiTransaction(data: {
    amountInUnitByReceiver: IMapValueByAddress<bigint>;
    senderAccount: IAccount;
    senderBalance: bigint;
    totalSendInUnit: bigint;
  }): Promise<IMapValueByAddress<string>> {
    const {amountInUnitByReceiver, senderAccount, senderBalance, totalSendInUnit} = data;
    const resultTxReceipt: IMapValueByAddress<string> = new Map()

    const {
      getLatestBlockhash, BatchTransaction, BatchRequest,
      sendRawTransaction, SystemProgram, getKeyPair, PublicKey
    } = this._web3Provider

    const signedTxByAddress = new BatchRequest()
    const signer = getKeyPair(senderAccount.privateKey)

    const {
      totalFee,
      computeLimitIx,
      computePriceIx
    } = await this._estimatePriorityFeeForMultiTransaction(data)

    const numTransactions = Math.ceil(amountInUnitByReceiver.size / this._txInstructionsPerTransaction);
    const rentExemptionInLamports = await this._getRentExemptionInLamports(senderAccount.address)
    const totalAmount = totalFee * BigInt(numTransactions) + totalSendInUnit
    if (senderBalance - totalAmount <= rentExemptionInLamports) {
      console.error("Not enough balance to send transactions")
      return resultTxReceipt
    }

    let chunkedReceivers: IMapValueByAddress<bigint>[] = []
    for (const chunk of partitionMapIntoChunks(amountInUnitByReceiver, this._txInstructionsPerTransaction)) {
      chunkedReceivers.push(chunk)
      const {blockhash} = await getLatestBlockhash("finalized")
      let transaction = new BatchTransaction({
        recentBlockhash: blockhash,
      })
      chunk.forEach((amount: bigint, address: AddressType) => {
        transaction.add(
          SystemProgram.transfer({
            fromPubkey: signer.publicKey,
            toPubkey: new PublicKey(address),
            lamports: amount,
          })
        );
      })

      if (computePriceIx) {
        transaction.add(
          computePriceIx,
        )
      }
      transaction.add(computeLimitIx)

      transaction.sign(signer)

      signedTxByAddress.add(sendRawTransaction.request(transaction.serialize(), chunk.keys().next().value))
    }

    const dataBatchTx = await signedTxByAddress.execute({timeout: 30000})
    for (let txResult of dataBatchTx) {
      if (txResult.error) {
        const errorData = txResult.error as JsonRpcError
        throw new Error(`${errorData.message} [${errorData.code}]`)
      }
      let itemSuccess = txResult as IRpcResponse
      if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
        itemSuccess.result += '?cluster=devnet'
      }
      const currentChunk = chunkedReceivers.find(chunk => chunk.has(itemSuccess.id))
      currentChunk?.forEach((_, address) => resultTxReceipt.set(address, itemSuccess.result))
    }
    return resultTxReceipt
  }

  fetchBalanceInUnit(address: AddressType): Promise<bigint> {
    const {getBalance} = this._web3Provider

    return getBalance(new PublicKey(address))
      .then(response => getBalance.outputFormatter(response))
  }

  toBaseCurrencyFromUnit(amount: bigint): string {
    const amountNum: number = this._web3Provider.lamport_to_sol(Number(amount))
    return amountNum.toFixed(getPrecisionByNumber(amountNum))
  }

  toUnitFromBaseCurrency(amount: string): bigint {
    return BigInt(this._web3Provider.sol_to_lamport(Number(amount)))
  }

  validateAddress(address: AddressType): boolean {
    try {
      return this._web3Provider.isAddress(address)
    } catch (e) {
      return false
    }
  }

  privateKeyToAccount(privateKey: PrivateKeyType): IAccount {
    return this._web3Provider.privateKeyToAccount(privateKey)
  }

  async setInfoForSendTransaction(senderAccount: IAccount): Promise<void> {}

  resetInfoForSendTransaction(): void {}
}

export {SOL_DisperseFacade}