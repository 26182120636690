import React, {useMemo, useState} from "react";
import * as Sentry from "@sentry/react";
import {
  AddressType,
  IMapValueByAddress,
  NetworkCurrencyEnum,
  NetworkTitleEnum,
  PrivateKeyType
} from "../../ConsolidationTool/types";
import {IDisperseToolView} from "../Views/DisperseToolView";
import {IWeb3DisperseFacade} from "../facades/IWeb3DisperseFacade";
import {partitionMapIntoChunks} from "../../../helpers/toChunks";
import {IDataEstimateDisperseTransactions, IDataSendDisperseTransactions} from "../types";

function withFacade(WrappedComponent: React.FC<IDisperseToolView>, facadeByCurrency: IWeb3DisperseFacade, initData?: {
  recipientsAndValuesForChargeInUnit: IMapValueByAddress<bigint>
}) {
  const initAmountInUnit = initData?.recipientsAndValuesForChargeInUnit || new Map<AddressType, bigint>([])
  const initAmountInBaseCurrency = new Map<AddressType, string>([])
  initAmountInUnit.forEach((value, address) => {
    initAmountInBaseCurrency.set(address, facadeByCurrency.toBaseCurrencyFromUnit(value))
  })

  return () => {
    const [amountInUnitByReceiver, setAmountInUnitByReceiver] = useState(initAmountInUnit)
    const [amountInBaseCurrencyByReceiver, setAmountInBaseCurrencyByReceiver] = useState(initAmountInBaseCurrency)
    const [txHashByAddress, setTxHashByAddress] = useState<IMapValueByAddress>(new Map())
    const [isProcessingSend, setIsProcessingSend] = useState<boolean>(false)
    const [isSuccessSend, setIsSuccessSend] = useState<true | undefined>()
    const [errorSend, setErrorSend] = useState<Error | undefined>()

    const [isSyncAmount, setIsSyncAmount] = useState(false)
    const [isParsing, setIsParsing] = useState(false)
    const [isEstimatingDisperse, setIsEstimatingDisperse] = useState(false)

    const [receiverInput, setReceiverInput] = useState<AddressType | null>(null)
    const [amountInCurrencyInput, setAmountInput] = useState<string | null>(null)

    const [feeEstimated, setFeeEstimated] = useState<{
      optimizedFeeInUnit?: bigint,
      notOptimizedFeeInUnit: bigint
    }>({notOptimizedFeeInUnit: BigInt(0)})
    const [isOptimizedApproach, setIsOptimizedApproach] = useState<boolean>(false)


    const [senderBalanceInUnit, setSenderBalanceInUnit] = useState<{
      address: AddressType | null,
      balance: bigint
    }>({address: null, balance: BigInt(0)})

    const [senderAccountPrivateKey, setSenderAccountPrivateKey] = useState<PrivateKeyType | null>(null)

    const senderPrivateKeyInputError = useMemo(() => {
      if (!senderAccountPrivateKey?.length) return null
      try {
        const account = facadeByCurrency.privateKeyToAccount(senderAccountPrivateKey.trim())
        facadeByCurrency.fetchBalanceInUnit(account.address)
          .then(balanceInUnit => {
            setSenderBalanceInUnit({address: account.address, balance: balanceInUnit})
          })
          .catch(error => {
            Sentry.captureException(error, {
              tags: {
                section: "disperse",
                method: "fetchBalanceInUnit"
              },
              contexts: {
                "senderPrivateKeyInput": {
                  network: facadeByCurrency.network,
                  currency: NetworkCurrencyEnum[facadeByCurrency.network],
                  count_keys: initAmountInUnit.size,
                  use_disperse: isEstimatingDisperse,
                  fee_estimated: feeEstimated,
                }
              }
            });
            console.error('fetchBalanceInUnit=>', error?.message || 'Smth was wrong!')
          })
      } catch (e: any) {
        Sentry.captureException(e, {
          tags: {
            section: "disperse",
            method: "privateKeyToAccount"
          },
          contexts: {
            "senderPrivateKeyInput": {
              network: facadeByCurrency.network,
              currency: NetworkCurrencyEnum[facadeByCurrency.network],
              count_keys: initAmountInUnit.size,
              use_disperse: isEstimatingDisperse,
              fee_estimated: feeEstimated,
            }
          }
        });
        return e?.message || `Private key is invalid!`
      }
      return null
    }, [senderAccountPrivateKey]);

    const totalSendInUnit = useMemo(() => {
      let __total: bigint = BigInt(0);
      if (!amountInUnitByReceiver.size) return __total

      amountInUnitByReceiver.forEach((value, key) => __total += value)
      return __total
    }, [amountInUnitByReceiver])

    const currentFeeInUnit = useMemo(() => {
      return isOptimizedApproach ? (feeEstimated.optimizedFeeInUnit || BigInt(0)) : feeEstimated.notOptimizedFeeInUnit
    }, [isOptimizedApproach, feeEstimated])

    const senderBalanceError = useMemo(() => {
      if (senderBalanceInUnit.address) {
        if (senderBalanceInUnit.balance <= (currentFeeInUnit + totalSendInUnit)) {
          return 'Insufficient funds for transfer'
        }
      }
      return null
    }, [senderBalanceInUnit, currentFeeInUnit, totalSendInUnit])

    const receiverInputError = useMemo(() => {
      if (!receiverInput?.length) return null
      if (!facadeByCurrency.validateAddress(receiverInput)) return 'Address is invalid!'

      return null
    }, [receiverInput]);


    const isLimitAddresses = useMemo(() => {
      return amountInBaseCurrencyByReceiver.size > facadeByCurrency.getLimitReceiver()
    }, [amountInBaseCurrencyByReceiver])

    const estimateIsDisable = amountInUnitByReceiver.size === 0 || isEstimatingDisperse || isLimitAddresses || senderBalanceError
    const sendIsDisable = estimateIsDisable || senderBalanceError || !currentFeeInUnit || (senderAccountPrivateKey && senderPrivateKeyInputError) || isProcessingSend

    function handleChangeAmount(amount: string) {
      setAmountInput(__validateInputNumber(amount))
    }

    function __validateInputNumber(amount: string) {
      amount = amount.replace(',', '.')
      let res = amount.match(/[0-9]{1,}\.?[0-9]{0,}/g)
      amount = (res?.[0] ? res?.[0] : '') as string
      let amountNum: number = parseFloat(amount)
      return isNaN(amountNum) ? null : amount
    }

    function editAmountByAddress(address: AddressType, amount: string) {
      const strAmount = __validateInputNumber(amount) || '0'

      if (isSyncAmount) {
        amountInUnitByReceiver.forEach((_amount, address) => {
          amountInUnitByReceiver.set(address, facadeByCurrency.toUnitFromBaseCurrency(strAmount))
          amountInBaseCurrencyByReceiver.set(address, strAmount)
        })
      } else {
        amountInUnitByReceiver.set(address, facadeByCurrency.toUnitFromBaseCurrency(strAmount))
        amountInBaseCurrencyByReceiver.set(address, strAmount)
      }
      setAmountInUnitByReceiver(new Map(amountInUnitByReceiver))
      setAmountInBaseCurrencyByReceiver(new Map(amountInBaseCurrencyByReceiver))
    }

    function deleteReceiver(address: AddressType) {
      amountInUnitByReceiver.delete(address)
      setAmountInUnitByReceiver(new Map(amountInUnitByReceiver))

      amountInBaseCurrencyByReceiver.delete(address)
      setAmountInBaseCurrencyByReceiver(new Map(amountInBaseCurrencyByReceiver))
      if (amountInBaseCurrencyByReceiver.size === 0) {
        setIsSyncAmount(false)
      }
    }

    function addReceiver() {
      if (receiverInput && amountInCurrencyInput) {
        editAmountByAddress(receiverInput, amountInCurrencyInput)
        __clearInput()
      }
    }

    function __clearInput() {
      setReceiverInput(null)
      setAmountInput(null)
      setIsSuccessSend(undefined)
    }


    async function handleEstimate() {
      if (isEstimatingDisperse || isLimitAddresses || !senderBalanceInUnit.address) return
      setIsEstimatingDisperse(true)
      setIsSuccessSend(undefined)
      let _optimizedFeeInUnit: bigint = BigInt(0)
      let _notOptimizedFeeInUnit: bigint = BigInt(0)

      const senderAccount = facadeByCurrency.privateKeyToAccount(senderAccountPrivateKey!)

      for (const chunk of partitionMapIntoChunks<AddressType, bigint>(amountInUnitByReceiver, facadeByCurrency.getChunkSize())) {
        let totalSendInUnit: bigint = BigInt(0)
        chunk.forEach((item: bigint) => totalSendInUnit += item)
        const data: IDataEstimateDisperseTransactions = {
          amountInUnitByReceiver: chunk,
          senderAccount: senderAccount,
          totalSendInUnit
        }

        await facadeByCurrency.estimateTransactions(data)
          .then(response => {
            _optimizedFeeInUnit += response.optimizedFeeInUnit
            _notOptimizedFeeInUnit += response.notOptimizedFeeInUnit
          })
          .catch(error => {
            setIsEstimatingDisperse(false)
            Sentry.captureException(error, {
              tags: {
                section: "disperse",
                method: "estimateTransactions"
              },
              contexts: {
                "handleEstimate": {
                  network: facadeByCurrency.network,
                  currency: NetworkCurrencyEnum[facadeByCurrency.network],
                  count_keys: initAmountInUnit.size,
                  use_disperse: isEstimatingDisperse,
                  fee_estimated: feeEstimated,
                }
              }
            });
          })
      }

      setFeeEstimated({
        optimizedFeeInUnit: _optimizedFeeInUnit,
        notOptimizedFeeInUnit: _notOptimizedFeeInUnit
      })

      setIsOptimizedApproach(_optimizedFeeInUnit < _notOptimizedFeeInUnit)

      setIsEstimatingDisperse(false)
      facadeByCurrency.resetInfoForSendTransaction()
    }

    async function handleSend() {
      if (sendIsDisable || !senderAccountPrivateKey) return
      let _resultTxReceiptByAddress: IMapValueByAddress = new Map()
      const senderAccount = facadeByCurrency.privateKeyToAccount(senderAccountPrivateKey!)

      setSenderAccountPrivateKey(null)
      setIsProcessingSend(true)
      await facadeByCurrency.setInfoForSendTransaction(senderAccount)

      for (const chunk of partitionMapIntoChunks(amountInUnitByReceiver, facadeByCurrency.getChunkSize())) {
        let totalSendInUnit: bigint = BigInt(0)
        chunk.forEach((item: bigint) => totalSendInUnit += item)
        const data: IDataSendDisperseTransactions = {
          amountInUnitByReceiver: chunk,
          senderAccount,
          totalSendInUnit,
          senderBalance: senderBalanceInUnit.balance,
          isOptimizedFee: isOptimizedApproach,
        }

        await facadeByCurrency.sendTransactions(data)
          .then(response => {
            _resultTxReceiptByAddress = new Map([..._resultTxReceiptByAddress, ...response])
          })
          .catch(error => {
            Sentry.captureException(error, {
              tags: {
                section: "disperse",
                method: "sendTransactions"
              },
              contexts: {
                "handleSend": {
                  network: facadeByCurrency.network,
                  currency: NetworkCurrencyEnum[facadeByCurrency.network],
                  count_keys: initAmountInUnit.size,
                  use_disperse: isEstimatingDisperse,
                  fee_estimated: feeEstimated,
                }
              }
            });
            setErrorSend(error?.message)
          })
      }

      setTxHashByAddress(_resultTxReceiptByAddress)
      setIsSuccessSend(true)
      setIsProcessingSend(false)
      facadeByCurrency.resetInfoForSendTransaction()
    }

    /**
     * parsing ctrl+v or paste list of value "address amountInCurrency" from exel
     */
    async function enterReceiver(value: string | AddressType) {
      console.log('Parsing...')
      setIsParsing(true)
      setTimeout(() => {
        let regExp;
        switch (facadeByCurrency.network) {
          case "eth":
          case "bsc":
          case "base":
            regExp = /(0x[\d\w]{40})[\s]{0,}([\d]{0,}[\.,]{0,}[\d]{0,})/g;
            break;
          case "sol":
            regExp = /([\d\w]{32,44})[\s]{0,}([\d]{0,}[\.,]{0,}[\d]{0,})/g;
            break;
        }
        const lines = (value ? Array.from(value.matchAll(regExp!)) : []) as [string, AddressType, string | ""][]
        if (lines.length > 1) {
          for (const line of lines) {
            const [allMatch, account, value] = line
            editAmountByAddress(account, (value ?? "0"))
          }
          __clearInput()
        } else {
          /**
           * default behavior
           */
          setReceiverInput(value)
        }
        setIsParsing(false)
        console.log('Parsed')
      }, 0)
    }

    const props = {
      networkCurrency: NetworkCurrencyEnum[facadeByCurrency.network],
      title: NetworkTitleEnum[facadeByCurrency.network],
      linkForTxScan: facadeByCurrency.linkForTxScan,
      amountByReceiver: {
        setAmountByReceiver: editAmountByAddress,
        removeReceiver: deleteReceiver,
        value: amountInBaseCurrencyByReceiver,
        total: facadeByCurrency.toBaseCurrencyFromUnit(totalSendInUnit),
        limit: facadeByCurrency.getLimitReceiver(),
        isLimit: isLimitAddresses,
        error: null,
      },
      receiverAndAmountInput: {
        receiver: {
          value: receiverInput,
          handleChange: enterReceiver,
          error: receiverInputError
        },
        amount: {
          value: amountInCurrencyInput,
          handleChange: handleChangeAmount
        },
        isLoading: isParsing,
        addReceiver,
      },
      syncAmount: {
        isSync: isSyncAmount,
        setIsSyncAmount
      },
      senderAccount: {
        address: {
          value: senderBalanceInUnit.address,
        },
        balance: {
          value: facadeByCurrency.toBaseCurrencyFromUnit(senderBalanceInUnit.balance),
          error: senderBalanceError
        },
        privateKey: {
          handleChange: setSenderAccountPrivateKey,
          error: senderPrivateKeyInputError
        }
      },
      estimate: {
        handleEstimate,
        isDisabled: estimateIsDisable,
        error: null,
        fee: {
          handleSelect: setIsOptimizedApproach,
          value: facadeByCurrency.toBaseCurrencyFromUnit(currentFeeInUnit),
          options: [
            feeEstimated.notOptimizedFeeInUnit ? {
              label: 'Single transactions',
              isOptimized: false,
              fee: facadeByCurrency.toBaseCurrencyFromUnit(feeEstimated.notOptimizedFeeInUnit),
              isSelected: !isOptimizedApproach,
            } : null,
            feeEstimated.optimizedFeeInUnit ? {
              fee: facadeByCurrency.toBaseCurrencyFromUnit(feeEstimated.optimizedFeeInUnit || BigInt(0)),
              isOptimized: true,
              label: 'Multi-transaction',
              isSelected: isOptimizedApproach,
            } : null
          ].filter(Boolean),
        },
        isLoading: isEstimatingDisperse
      },
      send: {
        txHashByAddress,
        handleSend,
        total: facadeByCurrency.toBaseCurrencyFromUnit(totalSendInUnit + currentFeeInUnit),
        isDisabled: sendIsDisable,
        isSuccess: isSuccessSend,
        isProcessing: isProcessingSend,
        error: errorSend
      },
    }

    return (
      <WrappedComponent {...props} />
    );

  }
}

export {withFacade}